<template>

  <div v-if="recaptcha.enabled && recaptcha.error" class="text-xs mb-4">

    <!-- This site is protected by reCAPTCHA and the Google -->
    <div class="flex items-center">
      <div class="px-1"><i class="text-4xl fa fa-warning text-red-600"></i></div>
      <div class="px-1">
        <div class="text-red-600">Blocked by reCAPTCHA</div>
        <div class="text-red-600 leading-none py-1">
          <a class="text-red-600 hover:text-red-700" href="https://policies.google.com/privacy" target="_blank">Privacy</a> -
          <a class="text-red-600 hover:text-red-700" href="https://policies.google.com/terms" target="_blank">Terms</a>
        </div>
      </div>
      <div class="px-2"><img src="@/assets/img/rc_logo_48.png" width="32" height="32" alt="reCAPTCHA logo"/></div>
    </div>

  </div>

</template>

<script>
export default {

  name: 'Recaptcha3',

  props: {

    data: { type: Object }

  },

  computed: {

    recaptcha () {

      let form = this.data.form || {};

      return form.recaptcha || { enabled: false };

    }

  }

}
</script>
